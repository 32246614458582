/* Variables
–––––––––––––––––––––––––––––––––––––––––––––––––– */
:root {
    --text-color: var(--black-080);
  
  /* black
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
    --black-100: hsl(0, 0%, 0%);
    --black-090: hsl(0, 0%, 10%);
    --black-080: hsl(0, 0%, 20%);
    --black-070: hsl(0, 0%, 30%);
    --black-060: hsl(0, 0%, 40%);
    --black-050: hsl(0, 0%, 50%);
    --black-040: hsl(0, 0%, 60%);
    --black-030: hsl(0, 0%, 70%);
    --black-020: hsl(0, 0%, 80%);
    --black-010: hsl(0, 0%, 90%);
    --black-000: hsl(0, 0%, 100%);
  
  /* blue
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
    --blue-090: hsl(217, 89%, 10%);
    --blue-080: hsl(217, 89%, 20%);
    --blue-070: hsl(217, 89%, 30%);
    --blue-060: hsl(217, 89%, 40%);
    --blue-050: hsl(217, 89%, 50%);
    --blue-040: hsl(217, 89%, 60%);
    --blue-030: hsl(217, 89%, 70%);
    --blue-020: hsl(217, 89%, 80%);
    --blue-010: hsl(217, 89%, 90%);
  
  /* green
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
    --green-090: hsl(136, 53%, 10%);
    --green-080: hsl(136, 53%, 20%);
    --green-070: hsl(136, 53%, 30%);
    --green-060: hsl(136, 53%, 40%);
    --green-050: hsl(136, 53%, 50%);
    --green-040: hsl(136, 53%, 60%);
    --green-030: hsl(136, 53%, 70%);
    --green-020: hsl(136, 53%, 80%);
    --green-010: hsl(136, 53%, 90%);
  
  /* red
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
    --red-090: hsl(5, 81%, 10%);
    --red-080: hsl(5, 81%, 20%);
    --red-070: hsl(5, 81%, 30%);
    --red-060: hsl(5, 81%, 40%);
    --red-050: hsl(5, 81%, 50%);
    --red-040: hsl(5, 81%, 60%);
    --red-030: hsl(5, 81%, 70%);
    --red-020: hsl(5, 81%, 80%);
    --red-010: hsl(5, 81%, 90%);
  
  /* yellow
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
    --yellow-090: hsl(45, 97%, 10%);
    --yellow-080: hsl(45, 97%, 20%);
    --yellow-070: hsl(45, 97%, 30%);
    --yellow-060: hsl(45, 97%, 40%);
    --yellow-050: hsl(45, 97%, 50%);
    --yellow-040: hsl(45, 97%, 60%);
    --yellow-030: hsl(45, 97%, 70%);
    --yellow-020: hsl(45, 97%, 80%);
    --yellow-010: hsl(45, 97%, 90%);
  }
