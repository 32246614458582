// div, aside, section {
//   border: 1px solid green;
// }

header {
  border-radius: 5px;
  margin: 60px 0 80px;
  overflow:hidden;
}

ul {
  line-height: 2.5;
  list-style-type: none;
  margin-left: -40px;
  margin-top: 30px;
  width: 400px;
  li {
    display: inline-block;
    font-size: 18px;
    line-height: 1;
  }
}

.row {
  display: flex;
  // margin: 20px auto;

  aside {
    display: flex;
    text-align: center;
    margin: auto;
    max-width: 33%;

    img {
      margin: auto;
      max-width: 400px;
    }

    div {
      display: flex;
    }
  }

  section {
    padding: 0 30px;
    width: 67%;
  }
}

.row-spacer {
  border-radius: 2px;
  background: linear-gradient(-120deg, var(--blue-060), var(--blue-080));
  height: 4px;
  margin: 60px 0;
}

.video-section-footer {
  display: flex;
  justify-content: center;
}

.video-link-wrapper {
  display: inline-block;
  max-width: 540px;
  margin-top: 20px;
  opacity: 0.8;
  position:relative;

  &:hover {
    opacity: 1.0;
  }

  .video-play-button {
    // background-image: url("https://www.westland.net/images/play-button.png") center center no-repeat;
    height: 70px;
    left: 50%;
    margin: -35px 0 0 -35px;
    pointer-events: none;
    position: absolute;
    top: 40%;
    width: 70px;
    // z-index: 1000;
  }
}

footer {
  padding: 0px 0 60px;
  text-align: center;
}

@media (max-width: 768px) {
  .row {
    flex-direction: column;
    aside {
      margin: auto;
      max-width: inherit;
      width: 100%;
      img {
        margin: auto;
      }

    }
    section {
      width: 100%;
    }
  }
}
